import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ValueProp from '../components/ValueProp';
import SocialProof from '../components/SocialProof';
import ProductBenefits from '../components/ProductBenefits';
import CallToAction from '../components/CallToAction';
import Newsletter from '../components/Newsletter';
import Testimonials from '../components/Testimonials';

const IndexPage = () => (
    <Layout>
        <SEO title="Aguaman | Botellones de agua a domicilio" pathname="/" />
        <ValueProp />
        <SocialProof />
        <ProductBenefits />
        <Newsletter />
        <Testimonials />
        <CallToAction />
    </Layout>
);

export default IndexPage;
